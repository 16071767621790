import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const radioGroupDefaultVariants = {}
const radioGroupCompoundVariants = []

const radioGroupSlotNames = [
  [
    "root",
    "radioGroup__root"
  ],
  [
    "item",
    "radioGroup__item"
  ],
  [
    "indicator",
    "radioGroup__indicator"
  ],
  [
    "icon",
    "radioGroup__icon"
  ]
]
const radioGroupSlotFns = /* @__PURE__ */ radioGroupSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, radioGroupDefaultVariants, getSlotCompoundVariant(radioGroupCompoundVariants, slotName))])

const radioGroupFn = memo((props = {}) => {
  return Object.fromEntries(radioGroupSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const radioGroupVariantKeys = []
const getVariantProps = (variants) => ({ ...radioGroupDefaultVariants, ...compact(variants) })

export const radioGroup = /* @__PURE__ */ Object.assign(radioGroupFn, {
  __recipe__: false,
  __name__: 'radioGroup',
  raw: (props) => props,
  variantKeys: radioGroupVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, radioGroupVariantKeys)
  },
  getVariantProps
})